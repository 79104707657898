import axiosService from "../axios-util/axios-services";



const uploadFileAWS = async (file: any) => {
  try {
    const formData = new FormData();
    formData.append("file", file);


    const response = await axiosService.post('/uploads', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });


    console.log(response.data, 'Upload Response');
    return response.data;
  } catch (error) {
    console.error(error, 'Upload Error');
    throw error;
  }
};


const s3UploadFiles = {
  uploadFileAWS,
};


export default s3UploadFiles;
